import React from 'react';
import { colors, mq, fontSizes } from '../../theme';
import WebsiteButton from '../WebsiteButton';
import Container from '../Container';
import { Paragraph, Heading3 } from '../Heading/Text';
import Image from '../Image';

const NextWebinar = ({ theme = 'light' }) => {
  return (
    <div
      id="next-webinar"
      css={{
        backgroundColor:
          theme === 'dark' ? colors.neutralDarker : colors.neutralWhite,
        color: theme === 'dark' ? colors.neutralWhite : colors.neutralBlack
      }}
    >
      <Container css={mq({ flexDirection: ['row', 'column'] })}>
        <Container.ContentLeft css={mq({ paddingRight: [60, 0] })}>
          <Heading3 light={theme === 'light'}>
            Envie d’en savoir plus&nbsp;?
            <br />
            <span>Inscrivez-vous à notre prochain webinar&nbsp;!</span>
          </Heading3>
          <Paragraph>
            Vous pouvez nous poser toutes vos questions par écrit pendant la
            séance. Ça ne dure que 15-20 min et c’est gratuit&nbsp;! <br />{' '}
            <br />
            Vous vous connectez à l’heure de votre choix dans les créneaux
            proposés. Vous voyez notre écran, on vous explique à vous et à tous
            les participants comment fonctionne Web2vi.
          </Paragraph>
          <div
            css={{
              textAlign: 'center'
            }}
          >
            <span
              css={mq({
                display: ['none', 'block']
              })}
            >
              <WebsiteButton
                href="http://info.web2vi.com/inscription-webinar"
                my={6}
              >
                Je réserve un horaire
              </WebsiteButton>
            </span>
          </div>
          <span
            css={mq({
              display: ['inline', 'none']
            })}
          >
            <WebsiteButton
              href="http://info.web2vi.com/inscription-webinar"
              trackLocation="Module Webinar"
            >
              Je réserve un horaire
            </WebsiteButton>
          </span>
        </Container.ContentLeft>
        <Container.ContentRight>
          <div
            css={{
              display: 'flex',
              alignItems: 'flex-start',
              flexDirection: 'column',
              backgroundColor: colors.neutralBlack,
              borderRadius: 10,
              overflow: 'hidden'
            }}
          >
            <Image src="image_webinar_web2vi_v2.jpg" css={{ width: '100%' }} />
            <div
              css={{
                padding: '15px 20px',
                color: colors.neutralWhite
              }}
            >
              <p
                css={{
                  fontSize: fontSizes.large,
                  textTransform: 'uppercase',
                  lineHeight: '24px',
                  fontWeight: 600
                }}
              >
                PROCHAIN WEBINAR :<br />
                <br />
                PRÉSENTATION DU LOGICIEL WEB2VI : DU MÉTRÉ À LA FACTURATION
              </p>
            </div>
          </div>
        </Container.ContentRight>
      </Container>
    </div>
  );
};

export default NextWebinar;
